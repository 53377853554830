import {Disclosure, Menu, Transition} from "@headlessui/react";
import {Fragment} from 'react'
import {NavLink, useNavigate} from "react-router-dom";
import classNames from "../utils/classNames";
import {IsAdmin, useAuth} from "../contexts/auth";
import braidedLogo from "../images/braided-weave.png"
import {UserIcon} from "@heroicons/react/outline";

const navigation = [
  { name: 'Dashboard', href: '/', current: true },
  { name: 'Organizations', href: '/organizations', current: false, admin:true },
  { name: 'Integrations', href: '/integrations', current: false },
  { name: 'Profiles', href: '/profiles', current: false},
  { name: 'Users', href: '/users', current: false, admin: true},
]

/**
 * Main application navigation
 * @returns {JSX.Element}
 * @constructor
 */
const Navbar = () => {
  const {removeToken} = useAuth();
  const navigate = useNavigate();

  return (
    <Disclosure as="nav" className="bg-indigo-600">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-16 w-16"
                    src={braidedLogo}
                    alt="Workflow"
                  />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => {
                      if (item.admin) {
                        return (
                          <IsAdmin key={item.name}>
                            <NavLink to={item.href} className={({isActive}) => {
                              return classNames(
                                isActive
                                  ? 'bg-indigo-700 text-white'
                                  : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                                'block px-3 py-2 rounded-md text-base font-medium text-xs'
                              )
                            }}>
                              {item.name}
                            </NavLink>
                          </IsAdmin>
                        )
                      } else {
                        return (
                          <NavLink key={item.name} to={item.href} className={({isActive}) => {
                            return classNames(
                              isActive
                                ? 'bg-indigo-700 text-white'
                                : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                              'block px-3 py-2 rounded-md text-base font-medium text-xs'
                            )
                          }}>
                            {item.name}
                          </NavLink>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-indigo-600 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <UserIcon className="w-6 text-white"/>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to="/user/profile" className={classNames(
                              active ? 'bg-gray-100' : '',
                              "block px-4 py-2 text-sm text-gray-700"
                              )}>
                              Your Profile
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button className={classNames(
                                active ? 'bg-gray-100' : '',
                              "text-left w-full block px-4 py-2 text-sm text-gray-700"
                              )} onClick={() => {
                                removeToken()
                                navigate("/login")
                            }}>
                              Sign Off
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}

export default Navbar;
