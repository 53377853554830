import {Bar, Line} from "react-chartjs-2";
import {useEffect, useState} from "react";
import axios from "axios";
import BarLoader from "../loaders/BarLoader";

const TransactionsAllClients = () => {
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('/dashboard/stats/totalsByAccount').then(({data}) => {
      setLabels(data.map(item => item.organization))
      setData(data.map(item => item.totals))
      setLoading(false)
    })
  }, [])

  return (
    <div className="bg-white rounded">
      <div className="py-3 px-5 w-full bg-gray-200 rounded-t-md">Current Month Transactions</div>
      <div className="p-3 flex justify-center items-center">
        {
          (loading && <BarLoader />) || (
            <Bar
              data={{
                labels,
                datasets: [{
                  data,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(188, 78, 16, 0.2)'
                  ],
                  borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(255, 159, 64)',
                    'rgb(255, 205, 86)',
                    'rgb(75, 192, 192)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)',
                    'rgb(188,78,16)'
                  ],
                  borderWidth: 1
                }]
              }}
              type="bar"
              options={{
                indexAxis: 'y',
                // Elements options apply to all of the options unless overridden in a dataset
                // In this case, we are setting the border of each horizontal bar to be 2px wide
                elements: {
                  bar: {
                    borderWidth: 2,
                  }
                },
                responsive: true,
                plugins: {
                  legend: {
                    position: 'right',
                    display: false
                  },
                  title: {
                    display: true,
                    text: 'Total Client Transactions'
                  }
                }
              }}
            />
          )
        }
      </div>
    </div>
  )
}

export default TransactionsAllClients;
