import Header from "../components/Header";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import ServicesDropDown from "../components/ServicesDropDown";
import {useNotification} from "../contexts/notification";
import {useAuth} from "../contexts/auth";

/**
 * Renders dynamic list of service profile fields
 * @param service
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileConfiguration = ({service, onSettingsChange}) => {
  const [configFields, setConfigFields] = useState();

  useEffect(() => {
    if (service.id) {
      axios.get(`/services/${service.id}/config`).then((response) => {
        setConfigFields(response.data)
      })
    }
  }, [service])

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 mt-5">
      <div className="px-4 py-5 sm:px-6 flex justify-between">
        Configure Profile
      </div>
      <div className="px-4 py-5 sm:p-6">
        {
          !configFields ?
            "Please select service to get started" :
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {
                Object.keys(configFields).map((field) => {
                  return (
                    <div className="sm:col-span-3" key={field}>
                      <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                        <label
                          htmlFor={field}
                          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                        >
                          {field}
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder={configFields[field].title}
                          onChange={(e) => {
                            onSettingsChange(field, e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              }
            </div>
        }
      </div>
    </div>
  );
}

/**
 * Renders save profile button and handles action
 * @param settings
 * @param serviceId
 * @param orgId
 * @param name
 * @returns {JSX.Element}
 * @constructor
 */
const SaveProfileButton = ({settings, serviceId, orgId, name}) => {
  const {addMessage} = useNotification();
  const navigate = useNavigate();

  const saveProfile = async () => {
    try {
      await axios.post(`/accounts/${orgId}/profiles`, {
        settings: settings,
        service_id: serviceId,
        name
      })

      addMessage('Successfully created profile.', 'success')
      navigate('/profiles')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <button
      type="button"
      className="bg-blue-500 mr-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={async () => {await saveProfile()}}
    >
      Save
    </button>
  )
}

const ProfileCreate = () => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [name, setName] = useState('');
  const [organizationId, setOrganizationId] = useState();
  const [service, setService] = useState({});
  const [settings, setSettings] = useState({});
  const {user} = useAuth();

  if (user.role.name === 'User') {
    navigate("/profiles")
  }

  useEffect(() => {
    axios.get('/accounts').then((response) => {
      setOrganizations(response.data)
      setOrganizationId(response.data[0].id)
    })

    return () => {
      setOrganizationId()
      setOrganizations([])
    }
  }, []);

  return (
    <main>
      <Header title="Create Profile" />
      <div className="pt-8 max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div>
          <p className="mt-1 text-sm text-gray-500">Create a new profile and add its configuration fields.</p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Organization
            </label>
            <div className="mt-1">
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => setOrganizationId(e.target.value)}
              >
                {organizations.map((organization) => {
                  return <option key={organization.id} value={organization.id}>{organization.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Services
            </label>
            <div className="mt-1">
              <ServicesDropDown onServiceSelected={setService}/>
            </div>
          </div>
        </div>
        <ProfileConfiguration service={service} onSettingsChange={(field, value) => {setSettings({...settings, [field]: value})}} />
        <div className="pt-5">
          <div className="flex justify-start">
            <button
              type="button"
              className="bg-white mr-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => navigate('/profiles')}
            >
              Back
            </button>
            <SaveProfileButton serviceId={service.id} orgId={organizationId} settings={settings} name={name} />
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProfileCreate;
