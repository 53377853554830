import Header from "../components/Header";
import axios from "axios";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PlusSmIcon as PlusSmIconSolid} from "@heroicons/react/solid";
import {SkeletonTable} from "../components/Loaders";
import CreateNewState from "../components/CreateNewState";
import {OfficeBuildingIcon} from "@heroicons/react/solid";

const OrganizationList = ({organizations}) => {
  const navigate = useNavigate();

  return organizations.map((organization) => {
    return (
      <tr key={organization.id}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{organization.name}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{organization.parent ? organization.parent.name : 'None'}</td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <button className="text-indigo-600 hover:text-indigo-900" onClick={() => {navigate(`/organizations/${organization.id}`)}}>
            Edit
          </button>
        </td>
      </tr>
    )
  })
}

/**
 *
 * @constructor
 */
const OrganizationsTable = ({organizations}) => {
  if (organizations.length === 0) {
    return <CreateNewState text="No Organizations. Create a new one." icon={<OfficeBuildingIcon className={"w-10 h-10 mx-auto"}/>} path="/organizations/create" />
  }

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Parent Org
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                <OrganizationList organizations={organizations} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Organizations = () => {
  let navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getOrganizations() {
      const {data} = await axios.get('/accounts')
      setOrganizations(data)
      setLoading(false)
    }

    getOrganizations();
  }, [])

  return (
    <main>
      <Header title="Organizations">
        <button
          type="button"
          className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => navigate('/organizations/create')}
        >
          <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
        </button>
      </Header>
      {
        loading ?
          <SkeletonTable rowCount={10} columnCount={4} /> :
          <OrganizationsTable organizations={organizations} />
      }
    </main>
  )
}

export default Organizations;
