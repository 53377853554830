import Header from "../components/Header";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

/**
 * Store and organization
 */
const StoreOrganization = ({name, parentOrg}) => {
  const navigate = useNavigate();

  const makeRequest = async () => {
    try {
      await axios.post('/accounts', {
        name,
        parent_id: parentOrg
      })

      navigate('/organizations')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <button
      type="submit"
      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={async () => {await makeRequest()}}
    >
      Save
    </button>
  )
}

const OrganizationCreate = () => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [name, setName] = useState();
  const [parentOrg, setParentOrg] = useState();

  useEffect(() => {
    axios.get('/accounts').then((response) => {
      setOrganizations(response.data)
    })
  }, [])


  return (
    <main>
      <Header title="Create Organization"/>
      <div className="pt-8 max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
          <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Parent Organization
            </label>
            <div className="mt-1">
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => setParentOrg(e.target.value)}
              >
                <option>None</option>
                {organizations.map((organization) => {
                  return <option key={organization.id} value={organization.id}>{organization.name}</option>
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-start">
            <button
              type="button"
              className="bg-white mr-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => navigate('/organizations')}
            >
              Back
            </button>
            <StoreOrganization name={name} parentOrg={parentOrg}/>
          </div>
        </div>
      </div>
    </main>
  )
}

export default OrganizationCreate;
