import {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import axios from "axios";
import BarLoader from "../loaders/BarLoader";

const TransactionsTotals = () => {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getStats() {
      const {data} = await axios.get(`/dashboard/stats/totals`)
      setLabels(data.map(item => item.month))
      setData(data.map(item => item.count))
      setLoading(false)
    }

    getStats();

    return () => {
      setLabels([]);
    };
  }, [])

  return (
    <div className="max-w-7xl bg-white rounded">
      <div className="py-3 px-5 w-full bg-gray-200 rounded-t-md">Month to Month Transactions</div>
      <div className="p-3 flex justify-center items-center">
        {
          (loading && <BarLoader />) || (
            <Line data={{
              labels,
              datasets: [{
                label: 'Transactions',
                data,
                fill: true,
                borderColor: '#818cf8',
                backgroundColor: '#818cf8',
                tension: 0.1,
              }]
            }} type="line" options={{
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false
                }
              }
            }}/>
          )
        }
      </div>
    </div>
  )
}

export default TransactionsTotals;
