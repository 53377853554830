import React, {useState, useCallback, useContext, useEffect} from 'react';
import axios from "axios";

export const getToken = () => {
  const token = localStorage.getItem('authToken');
  return token;
}

export const AuthContext = React.createContext({
  token: null,
  user: null,
  addToken: () => {},
  removeToken: () => {},
});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState({
    role: {
      name: 'User'
    }
  });

  useEffect(() => {
    if (token) {
      async function getUser() {
        const {data} = await axios.get('/user')
        setUser(data)
      }

      getUser()
    }
  }, [token]);


  const addToken = (token) => {
    localStorage.setItem('authToken', token)
    setToken(token)
  };

  const removeToken = () => {
    localStorage.clear();
    setToken(null)
    setUser({
      role: {
        name: 'User'
      }
    })
  };

  const contextValue = {
    token,
    addToken: useCallback((token) => addToken(token), []),
    removeToken: useCallback(() => removeToken(), []),
    user
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const { token, addToken, removeToken, user} = useContext(AuthContext);
  return { token, addToken, removeToken, user};
}

export const IsAdmin = ({children}) => {
  const {user} = useAuth();

  if (user.role.name === 'Admin' || user.role.name === 'Super Admin') {
    return (children)
  } else {
    return null;
  }
}
