import Header from "../components/Header";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import StepConfiguration from "../components/StepConfiguration";
import cronstrue from 'cronstrue';
import {PlusSmIcon as PlusSmIconSolid} from "@heroicons/react/solid";

/**
 * Renders icon for workflow step
 * @param step
 * @returns {JSX.Element}
 * @constructor
 */
const WorkflowStepActionIcon = ({step}) => {
  const getActions = [
    'search',
    'acceptWebhook',
    'getAttachedContent',
    'listUpdatedByDateRange',
    'listByDateRange',
    'getById'
  ];

  if (getActions.includes(step.settings.method)) {
    return (
      <svg className="w-7 h-7 text-white text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"/>
      </svg>
    )
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-7 h-7 text-white text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
      </svg>
    )
  }
}

/**
 * Displays which services are integrated via the integration
 * @constructor
 * @param steps
 */
const ServicesIntegrated = ({steps}) => {
  if (steps.length && ! steps[0].service_profile_id) {
    return 'Add profiles to steps'
  }

  const servicesIntegrated = steps.reduce((prev, current) => {
    prev[current.service.name] = current.service;
    return prev;
  }, {});

  return (
    <div className="flex justify-start items-center">
      {
        Object.keys(servicesIntegrated).map((service) => {
          return <img className="h-1/6 pr-2" style={{"maxWidth": "5rem"}} src={servicesIntegrated[service].image} alt=""/>
        })
      }
    </div>
  )
}

const addStep = (integration, setIntegration, setActiveStep) => {
  setIntegration({...integration, steps: integration.steps.concat([{
      settings: {
        service_profile_id: null,
        entity: '',
        method: ''
      },
      name: 'Change me'
  }])})

  if (integration.steps.length === 0) {
    setActiveStep({
      settings: {
        service_profile_id: null,
        entity: '',
        method: ''
      },
      name: 'Change me'
    })
  }
}

const IntegrationEdit = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [integration, setIntegration] = useState({
    name: '',
    steps: [],
    account: {
      name: ''
    }
  });

  const [activeStep, setActiveStep] = useState({
    settings: {
      service_profile_id: null,
      entity: '',
      method: ''
    }
  });

  useEffect(() => {
    axios.get(`/workflows/${urlParams.integrationId}`).then((response) => {
      setIntegration(response.data)
      setActiveStep(response.data.steps[0])
    })
  }, [urlParams])

  return (
    <main>
      <Header title="Edit Workflow"/>
      <div className="pt-8 max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <main className="py-10">
          {/* Page header */}
          <div
            className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{integration.name}</h1>
                <p className="text-sm font-medium text-gray-500">
                  {integration.description}
                </p>
              </div>
            </div>
            <div
              className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                Back
              </button>
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                Save
              </button>
            </div>
          </div>

          <div
            className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <section aria-labelledby="timeline-title" className="lg:col-start-1 lg:col-span-1">
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <div className="flex justify-between items-center">
                  <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                    Steps
                  </h2>
                  <button
                    type="button"
                    className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => addStep(integration, setIntegration, setActiveStep)}
                  >
                    <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>

                <div className="mt-6 flow-root">
                  <ul className="-mb-8">
                    {integration.steps.map((step, itemIdx) => (
                      <li key={step.id} onClick={() => {setActiveStep(step)}} >
                        <div className="relative pb-8">
                          {itemIdx !== integration.steps.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3 cursor-pointer">
                            <div>
                              <span
                                className={'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'}
                              >
                                <WorkflowStepActionIcon step={step}/>
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              <div>
                                <p className="text-md text-gray-700">
                                  {step.name}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {step.settings.entity}: {step.settings.method}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-6 flex flex-col justify-stretch">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled"
                  >
                    Send Test Message
                  </button>
                </div>
              </div>
            </section>
            <div className="space-y-6 lg:col-start-2 lg:col-span-2">
              <div className="grid grid-cols-2 gap-4">
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg min-h-full">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                        Integration Information
                      </h2>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Organization</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <button className="text-indigo-600 hover:text-indigo-900" onClick={() => navigate(`/organizations/${integration.account.id}`)}>
                              {integration.account.name}
                            </button>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Services Integrated</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <ServicesIntegrated steps={integration.steps} />
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </section>
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg min-h-full">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                        Triggers
                      </h2>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Type</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {integration.task ? 'Poll' : 'Webhook'}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Configuration</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {integration.task ? cronstrue.toString(integration.task.frequency) : ''}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </section>
              </div>
              {
                integration.steps.length === 0 ?
                  <p>Add steps to get started</p> :
                  <StepConfiguration step={activeStep} />
              }
            </div>
          </div>
        </main>
      </div>
    </main>
  )
}

export default IntegrationEdit;
