import Header from "../components/Header";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import TransactionsByIntegration from "../components/stats/TransactionsByIntegration";
import TransactionsSuccessAndErrorsByMonth from "../components/stats/TransactionsSuccessAndErrorsByMonth";
// import TransactionsByResource from "../components/stats/TransactionsByResource";
import {Tab} from "@headlessui/react";
import {MapIcon, ChartSquareBarIcon, TableIcon} from "@heroicons/react/solid";
import classNames from "../utils/classNames";
import Transactions from "../components/integrations/Transactions";
import IntegrationMapping from "../components/integrations/IntegrationMapping";
import {useNotification} from "../contexts/notification";

const tabs = [
  { name: 'Dashboard', href: '#overview', icon: ChartSquareBarIcon, current: false},
  { name: 'Transactions', href: '#', icon: TableIcon, current: false },
  { name: 'Mapping', href: '#', icon: MapIcon, current: false },
]

/**
 * Main dashboard view for an integration
 * @returns {JSX.Element}
 * @constructor
 */
const IntegrationView = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const { addMessage } = useNotification();
  const [integration, setIntegration] = useState({
    name: '',
    steps: [],
    account: {
      name: ''
    }
  });

  useEffect(() => {
    async function getIntegration() {
      try {
        const {data} = await axios.get(`/workflows/${urlParams.integrationId}`)
        setIntegration(data)
      } catch (e) {
        if (e.response.status === 403) {
          addMessage('You do not have permissions to view this resource.', 'error')
          navigate("/integrations")
        }
      }
    }

    getIntegration();
  }, [urlParams])

  return (
    <main>
      <Header title="View Workflow" />
      <div className="pt-8 max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <main>
          {/* Page header */}
          <div
            className="max-w-3xl rounded mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8 lg:py-8 bg-white">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{integration.name}</h1>
                <p className="text-sm font-medium text-gray-500">
                  {integration.description}
                </p>
              </div>
            </div>
            <div
              className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                type="button"
                onClick={() => navigate('/integrations')}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Back
              </button>
            </div>
          </div>
          <Tab.Group>
            <Tab.List className="mb-5 mt-5">
              {tabs.map((tab) => (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
                    )
                  }>
                  <div className="flex justify-center">
                    <tab.icon className="w-6 h-6 mr-1"/>
                    {tab.name}
                  </div>

                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="mt-2 grid grid-cols-2 gap-5">
                  <div>
                    <TransactionsSuccessAndErrorsByMonth integrationId={urlParams.integrationId}/>
                    {/*<TransactionsByResource integrationId={urlParams.integrationId}/>*/}
                  </div>
                  <div>
                    <TransactionsByIntegration integrationId={urlParams.integrationId}/>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <Transactions integrationId={urlParams.integrationId}/>
              </Tab.Panel>
              <Tab.Panel>
                <IntegrationMapping integration={integration}/>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </main>
      </div>
    </main>
  )
}

export default IntegrationView;
