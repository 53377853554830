import Header from "../components/Header";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import classNames from "../utils/classNames";

/**
 * Handles 3-legged auth flow
 * @param profileId
 * @constructor
 */
const ThreeLeggedAuth = ({profileId}) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const makeAuthRequest = async (authorizationCode) => {
    const request = await axios.get(`https://tgqtzjfg7d.execute-api.us-east-1.amazonaws.com/oauth/callback?authorizationCode=${authorizationCode}`)

    await axios.put(`/profiles/${profileId}`, {
      internal_settings: request.data
    })

    setIsAuthenticating(false)
    setIsAuthenticated(true)
  }

  if (isAuthenticating) {
    return (
      <div className="sm:col-span-3">
        <div className="mt-1">
          <input
            type="text"
            name="first-name"
            id="first-name"
            autoComplete="given-name"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            onChange={async (e) => {
              await makeAuthRequest(decodeURI(e.target.value))
            }}
            placeholder="Paste authorization code here"
          />
        </div>
      </div>
    )
  }

  if (isAuthenticated) {
    return (
      <svg className="text-emerald-600 w-20 h-20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
      </svg>
    )
  }

  return (
    <button
      type="button"
      className="flex align-center bg-emerald-300 py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-emerald-900 hover:bg-emerald-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={() => {
        setIsAuthenticating(true)
        window.open('https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9kBt168mda_8emgy1dqPFvHrYj4hCbDtUmWpaAL5OSth7_Jo0fqFdjgaj8ogZgyLVpjeGRwcCuLPL4yq1&redirect_uri=https://tgqtzjfg7d.execute-api.us-east-1.amazonaws.com/oauth/callback&response_type=code', '_blank')
      }}
    >
      Validate &nbsp;
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
      </svg>
    </button>
  );
}

const ProfileView = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [profile, setProfile] = useState({
    name: '',
    account_id: '',
    account: {},
    service: {
      image: ''
    },
    isValid: false
  });

  const [name, setName] = useState([]);

  useEffect(() => {
    axios.get(`/profiles/${urlParams.profileId}?isValid`).then((response) => {
      setProfile(response.data)
      setName(response.data.name)
    })
  }, [urlParams])

  return (
    <main>
      <Header title={`View Profile - ${profile.name}`}/>
      <div className="pt-8 max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div>
          <p className="mt-1 text-sm text-gray-500">Contains information about an authentication profile.</p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                id="first-name"
                value={name}
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                disabled
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="profile-account-name" className="block text-sm font-medium text-gray-700">
              Organization
            </label>
            <div className="mt-1">
              <select
                id="profile-account-name"
                name="profile-account-name"
                autoComplete="profile-account-name-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={profile.account.name}
                disabled
              >
                <option value={profile.account.name}>{profile.account.name}</option>
              </select>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Service
            </label>
            <div className="mt-1">
              {
                profile.service.image ?
                  (<img src={profile.service.image} alt="Service Logo" style={{"maxWidth": "15%"}}/>) :
                  null
              }
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              Valid
            </label>
            <div className="mt-1">
              {
                profile.service.name === 'Salesforce' && !profile.isValid ?
                  <ThreeLeggedAuth profileId={profile.id} /> :
                  <svg className={classNames(
                    profile.isValid
                      ? 'text-emerald-600'
                      : 'text-rose-600',
                    'w-20 h-20'
                  )} fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                  </svg>
              }
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-start">
            <button
              type="button"
              className="bg-white mr-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => navigate('/profiles')}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProfileView;
