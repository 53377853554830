import './App.css';
import Navbar from "./components/Navbar";
import {Navigate, Route, Routes} from "react-router-dom";
import Organizations from "./pages/Organizations";
import Integrations from "./pages/Integrations";
import Profiles from "./pages/Profiles";
import OrganizationCreate from "./pages/OrganizationCreate";
import OrganizationEdit from "./pages/OrganizationEdit";
import ProfileView from "./pages/ProfileView";
import IntegrationEdit from "./pages/IntegrationEdit";
import Users from "./pages/Users";
import ProfileCreate from "./pages/ProfileCreate";
import Dashboard from "./pages/Dashboard";
import UserProfile from "./pages/UserProfile";
import {NotificationProvider} from "./contexts/notification";
import Notification from "./components/Notification";
import Login from "./pages/Login";
import {AuthProvider, useAuth} from "./contexts/auth";
import IntegrationCreate from "./pages/IntegrationCreate";
// import UserCreate from "./pages/UserCreate";
import IntegrationView from "./pages/IntegrationView";

const RequireAuth = ({children}) => {
  const {token} = useAuth();

  if (!token) {
    return <Navigate to="/login" />
  }

  return (
    <div>
      <Navbar />
      {children}
    </div>
  )
}

const App = () => {

  return (
    <div className="min-h-full">
      <AuthProvider>
        <NotificationProvider>
          <Notification />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<RequireAuth>
              <Dashboard />
            </RequireAuth>} />
            <Route path="/organizations" element={
              <RequireAuth>
                <Organizations />
              </RequireAuth>
            } />
            <Route path="/organizations/create" element={
              <RequireAuth>
                <OrganizationCreate />
              </RequireAuth>
            } />
            <Route path="/organizations/:orgId" element={
              <RequireAuth>
                <OrganizationEdit />
              </RequireAuth>
            } />
            <Route path="/integrations" element={
              <RequireAuth>
                <Integrations />
              </RequireAuth>
            } />
            {/*<Route path="/integrations/create" element={*/}
            {/*  <RequireAuth>*/}
            {/*    <IntegrationCreate />*/}
            {/*  </RequireAuth>*/}
            {/*} />*/}
            <Route path="/integrations/:integrationId" element={
              <RequireAuth>
                <IntegrationView />
              </RequireAuth>
            } />
            <Route path="/integrations/:integrationId/edit" element={
              <RequireAuth>
                <IntegrationEdit />
              </RequireAuth>
            } />
            <Route path="/profiles" element={
              <RequireAuth>
                <Profiles />
              </RequireAuth>
            } />
            <Route path="/profiles/:profileId" element={
              <RequireAuth>
                <ProfileView />
              </RequireAuth>
            } />
            <Route path="/profiles/create" element={
              <RequireAuth>
                <ProfileCreate />
              </RequireAuth>
            } />
            <Route path="/users" element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            } />
            <Route path="/user/profile" element={
              <RequireAuth>
                <UserProfile />
              </RequireAuth>
            } />
            {/*<Route path="/users/create" element={*/}
            {/*  <RequireAuth>*/}
            {/*    <UserCreate />*/}
            {/*  </RequireAuth>*/}
            {/*} />*/}
          </Routes>
        </NotificationProvider>
      </AuthProvider>
      <footer>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">&copy; 2021 Braided Technologies LLC. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
