import React, {useState, useCallback, useContext} from 'react';

export const NotificationContext = React.createContext({
  message: null,
  type: null,
  addMessage: () => {
  },
  removeMessage: () => {
  }
});

export const NotificationProvider = ({children}) => {
  const [message, setMessage] = useState(null);
  const [type, setType] = useState('success');

  const removeMessage = () => setMessage(null);

  const addMessage = (message, type) => {
    setMessage(message);
    setType(type);

    setTimeout(() => {
      setMessage(null)
    }, 5000)
  }

  const contextValue = {
    message,
    type,
    addMessage: useCallback((message, type = 'success') => addMessage(message, type), []),
    removeMessage: useCallback(() => removeMessage(), [])
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotification = () => {
  const {message, type, addMessage, removeMessage} = useContext(NotificationContext);
  return {message, type, addMessage, removeMessage};
}
