import Header from "../components/Header";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNotification} from "../contexts/notification";

const roles = [{
  id: 1,
  name: 'Super Admin'
}, {
  id: 2,
  name: 'Partner'
}, {
  id: 3,
  name: 'Admin'
}, {
  id: 4,
  name: 'User'
}]

/**
 * Renders save button and handles save for user profile
 */
const EditUserProfile = ({name, email}) => {
  const { addMessage } = useNotification();

  const makeRequest = async () => {
    try {
      await axios.post(`/user`, {
        name,
        email
      })

      addMessage('Successfully updated profile.', 'success')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <button
      type="submit"
      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={async () => {
        await makeRequest()
      }}
    >
      Save
    </button>
  )
}

/**
 * User profile page and edit
 * @returns {JSX.Element}
 * @constructor
 */
const UserProfile = () => {
  const [user, setUser] = useState({
    name: '',
    account: {},
    role: {}
  });

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    axios.get('/user').then((response) => {
      setUser(response.data)
      setName(response.data.name)
      setEmail(response.data.email)
    })
  }, [])


  return (
    <main>
      <Header title="User Profile"/>
      <div className="pt-8 max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div>
          <p className="mt-1 text-sm text-gray-500">Edit your profile.</p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                value={name}
                id="first-name"
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Parent Organization
            </label>
            <div className="mt-1">
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                disabled
              >
                <option>{user.account.name}</option>
              </select>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                value={email}
                id="first-name"
                autoComplete="given-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <div className="mt-1">
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={user.role.id}
                disabled
              >
                {roles.map((role) => {
                  return <option key={role.id} value={role.id}>{role.name}</option>
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-start">
            <EditUserProfile name={name} email={email}/>
          </div>
        </div>
      </div>
    </main>
  )
}

export default UserProfile;
