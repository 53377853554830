import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import {BrowserRouter} from "react-router-dom";
import {getToken} from "./contexts/auth";
import {
  ArcElement, BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title, Tooltip
} from "chart.js";

ChartJS.register([CategoryScale, LinearScale, PointElement, LineElement, Legend, Title, ArcElement, Tooltip, BarElement]);

axios.defaults.baseURL = process.env.API_URL

axios.interceptors.request.use(function (config) {
  if (config.baseURL.match("v3") && !config.url.match("login")) {
    config.headers.authorization = `Bearer ${getToken()}`
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(function (config) {
  return config;
}, function (error) {
  if ([401].includes(error.response.status) && !error.response.config.url.match("login") && error.response.config.url !== "/user") {
    localStorage.clear()
    window.location.href = '/login'
  }

  return Promise.reject(error);
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
