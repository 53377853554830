import {Doughnut} from "react-chartjs-2";
import {useEffect, useState} from "react";
import axios from "axios";

/**
 * Displays a doughnut chart showing success and failures for
 * a particular organization
 * @param integrationId
 * @returns {JSX.Element}
 * @constructor
 */
const TransactionsByIntegration = ({integrationId}) => {
  const [stats, setStats] = useState([]);
  useEffect(() => {
    async function getStats() {
      const {data: {success, fail}} = await axios.get(`/dashboard/stats/transactionsByIntegration/${integrationId}`)
      setStats([success, fail])
    }

    getStats();

    return () => {
      setStats([])
    }
  }, [integrationId])

  return (
    <div className="max-w-7xl h-full mx-auto bg-white rounded">
      <div className="py-3 px-5 w-full bg-gray-200 rounded-t-md">Transaction Totals</div>
      <Doughnut
        data={{
          labels: ['Success', 'Error'],
          datasets: [{
            label: 'My First Dataset',
            data: stats,
            backgroundColor: [
              '#818cf8',
              '#fb7185',
            ],
          }],
          hoverOffset: 4
        }}
        type='doughnut' options={{
        plugins: {
          legend: {
            display: true
          },
          title: {
            text: 'Transaction Totals',
            display: false
          },
          tooltip: {
            enabled: true
          }
        }
      }}/>
    </div>
  );
}

export default TransactionsByIntegration;
