import {useEffect, useState} from 'react'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import UserHeader from "../components/UserHeader";
import {Bar} from "react-chartjs-2";
import TransactionsAllClients from "../components/widgets/TransactionsAllClients";
import TransactionsTotals from "../components/widgets/TransactionTotals";

/**
 * Main Dashboard component for app homepage
 * @returns {JSX.Element}
 * @constructor
 */
const Dashboard = () => {
  const [stats, setStats] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('/dashboard/stats').then((response) => {
      setStats(response.data)
    })
  }, [])

  return (
    <main className="flex-1 pb-8">
      {/* Page header */}
      <UserHeader />
      <div className="mt-8">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
          <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {/*<card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />*/}
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">Integrations Live</dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">{stats.integrationsCount}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <div className="cursor-pointer font-medium text-cyan-700 hover:text-cyan-900" onClick={() => {navigate('/integrations')}}>
                    View
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {/*<card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />*/}
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">Transactions Today</dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">{stats.incomingMessageCount}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {/*<card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />*/}
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">Total Tx - All Time</dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">{stats.totalCount}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-4">
            <TransactionsAllClients />
            <TransactionsTotals />
          </div>
        </div>
      </div>
    </main>
  )
}

export default Dashboard;
