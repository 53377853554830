import {useEffect, useState} from "react";
import axios from "axios";
import { CreditCardIcon, OfficeBuildingIcon, UserIcon, UsersIcon } from '@heroicons/react/solid'
import {Tab} from '@headlessui/react'
import StepConfigurationOverviewTab from "./StepConfigurationOverviewTab";
import StepConfigurationFiltersTab from "./StepConfigurationFiltersTab";
import StepConfigurationMappingTab from "./StepConfigurationMappingTab";
import StepConfigurationConditionsTab from "./StepConfigurationConditionsTab";

const tabs = [
  { name: 'Overview', href: '#overview', icon: UserIcon, current: false },
  { name: 'Mapping', href: '#', icon: OfficeBuildingIcon, current: false },
  { name: 'Filters', href: '#', icon: UsersIcon, current: true },
  { name: 'Conditions', href: '#', icon: CreditCardIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/**
 * Main container which holds step level configuration
 * @param step
 * @returns {JSX.Element}
 * @constructor
 */
const StepConfiguration = ({step}) => {
  const [serviceProfile, setServiceProfile] = useState({});
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (step.settings.service_profile_id) {
      axios.get(`/profiles/${step.settings.service_profile_id}`).then((response) => {
        setServiceProfile(response.data)
        setLoading(false)
      })
    }
  }, [step])

  if (loading) {
    return (
      <div className="bg-white shadow sm:rounded-lg flex justify-center items-center">
        <div
          className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"
        />
      </div>
    )
  }

  return (
    <section aria-labelledby="applicant-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
            Step Configuration
          </h2>
        </div>
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <Tab.Group>
          <Tab.List className="mb-5">
            {tabs.map((tab) => (
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm'
                  )
                }>
                {tab.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <StepConfigurationOverviewTab step={step} serviceProfile={serviceProfile} />
            </Tab.Panel>
            <Tab.Panel>
              <StepConfigurationMappingTab step={step} serviceProfile={serviceProfile} />
            </Tab.Panel>
            <Tab.Panel>
              <StepConfigurationFiltersTab step={step} serviceProfile={serviceProfile} />
            </Tab.Panel>
            <Tab.Panel>
              <StepConfigurationConditionsTab step={step} serviceProfile={serviceProfile} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </section>
  );
}

export default StepConfiguration;
