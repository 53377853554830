import {useNavigate} from "react-router-dom";

const CreateNewState = ({text, icon, path}) => {
  const navigate = useNavigate();
  return (
    <div className="max-w-7xl mx-auto">
      <button
        type="button"
        className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => navigate(path)}
      >
        {icon}

        <span className="mt-2 block text-sm font-medium text-gray-900">{text}</span>
      </button>
    </div>
  )
}

export default CreateNewState;
