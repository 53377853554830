/**
 * Renders filter items in filter list table
 * @param filters
 * @returns {unknown[]}
 * @constructor
 */
const FiltersList = ({filters}) => {
  return Object.keys(filters).map((filterKey) => {
    return (
      <tr key={filterKey}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{filterKey}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{filters[filterKey].operator}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{typeof filters[filterKey].value === "string" ? filters[filterKey].value : filters[filterKey].value.join(', ')}</td>
      </tr>
    )
  })
}

/**
 * Renders list of filters for a step
 * @param filters
 * @returns {JSX.Element}
 * @constructor
 */
const FiltersListTable = ({filters}) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Field
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Operator
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Compare Value
        </th>
      </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        <FiltersList filters={filters} />
      </tbody>
    </table>
  )
}

/**
 * Filters area of step configuration
 * @param step
 * @param serviceProfile
 * @returns {JSX.Element}
 * @constructor
 */
const StepConfigurationFiltersTab = ({step, serviceProfile}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {
              step.settings.filter ?
                <FiltersListTable filters={step.settings.filter} /> :
                <p className="min-w-full divide-y divide-gray-200 p-6">No filters</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepConfigurationFiltersTab;
