import Header from "../components/Header";
import axios from "axios";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {EyeIcon, PencilIcon, PlusSmIcon as PlusSmIconSolid, ShieldCheckIcon} from "@heroicons/react/solid";
import {SkeletonTable} from "../components/Loaders";
import CreateNewState from "../components/CreateNewState";
import {IsAdmin} from "../contexts/auth";

/**
 * Render profile items
 * @param profiles
 * @returns {*}
 * @constructor
 */
const ProfileList = ({profiles}) => {
  const navigate = useNavigate();

  return profiles.map((profile) => {
    return (
      <tr key={profile.id}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{profile.name}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><img style={{"height": "50px"}} src={profile.service.image} alt="Service Logo"/></td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{profile.account.name}</td>
        <td className="px-6 py-4 whitespace-nowrap text-right">
          <div className="flex items-center">
            <EyeIcon className="h-6 w-6 cursor-pointer" aria-hidden="true" onClick={() => navigate(`/profiles/${profile.id}`)}/>
            <IsAdmin>
              <PencilIcon className="h-6 w-6 cursor-pointer" aria-hidden="true" onClick={() => navigate(`/profiles/${profile.id}`)} />
            </IsAdmin>
          </div>
        </td>
      </tr>
    )
  })
}

const ProfilesTable = ({profiles}) => {
  if (profiles.length === 0) {
    return <CreateNewState
      text="No Profiles. Create a new one."
      icon={<ShieldCheckIcon className={"w-10 h-10 mx-auto"}/>}
      path="/profiles/create"
    />
  }

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Service
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Org Name
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                <ProfileList profiles={profiles} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Profiles = () => {
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/profiles').then((response) => {
      setProfiles(response.data)
      setLoading(false)
    })
  }, [])

  return (
    <main>
      <Header title="Profiles">
        <IsAdmin>
          <button
            type="button"
            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => navigate('/profiles/create')}
          >
            <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
          </button>
        </IsAdmin>
      </Header>
      {
        loading ?
          <SkeletonTable rowHeightClass="h-10" columnCount={4} rowCount={10}/> :
          <ProfilesTable profiles={profiles} />
      }
    </main>
  )
}

export default Profiles;
