const StepConfigurationOverviewTab = ({step, serviceProfile}) => {
  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6" id="overview">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Description</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {step.name}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Resource</dt>
          <dd className="mt-1 text-sm text-gray-900">{step.settings.entity}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Method</dt>
          <dd className="mt-1 text-sm text-gray-900">{step.settings.method}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Service Profile</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <a target="__blank" className="text-indigo-600 hover:text-indigo-900" href={`/profiles/${serviceProfile.id}`}>
              {serviceProfile.name}
            </a>
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Results Required</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {step.settings.results_required ? 'Yes' : 'No'}
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default StepConfigurationOverviewTab;
