import classNames from "../utils/classNames";

/**
 * Contains a skeleton loader table
 * @param columnCount
 * @param rowCount
 * @param rowHeightClass
 * @returns {JSX.Element}
 * @constructor
 */
export const SkeletonTable = ({columnCount = 5, rowCount = 5, rowHeightClass = "h-6"}) => {
  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 animate-pulse">
                <tr>
                  {
                    new Array(columnCount).fill('nothing').map((_, index) => {
                      return <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        key={index}
                      >
                        <div className="h-6 bg-gray-200 rounded" />
                      </th>
                    })
                  }
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {new Array(rowCount).fill('nothing').map((_, index) => (
                  <tr key={index} className="animate-pulse">
                    {
                      new Array(columnCount).fill('nothing').map((_, index) => {
                        return <td key={index} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <div className={classNames("bg-gray-200 rounded", rowHeightClass)} />
                        </td>
                      })
                    }
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
