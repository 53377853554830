import axios from "axios";
import {useEffect, useState} from "react";

const Loader = () => {
  return (
    <div className="flex justify-center items-center">
      <div
        className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"
      />
    </div>
  )
}

const MappedValue = ({mapping}) => {
  if (mapping.source) {
    return (
      <span>{mapping.source}</span>
    );
  }

  return (
    <span>{mapping.default}</span>
  );
}

const MappingListTable = ({step, contexts}) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Field
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Value
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Required
        </th>
      </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
      {
        Object.keys(step.settings.map).filter((field) => field !== "fields").map((mapKey) => {
          return (
            <tr key={mapKey}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{mapKey}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <MappedValue mapping={step.settings.map[mapKey]} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{contexts[mapKey].isRequired ? 'Yes' : 'No'}</td>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
}

const StepConfigurationMappingTab = ({step, serviceProfile}) => {
  const [contexts, setContexts] = useState();
  const loadingInitialState = Boolean(step.settings.map);
  const [loading, setLoading] = useState(loadingInitialState);

  useEffect(() => {
    if (step.settings.service_profile_id && step.settings.map) {
      axios.get(`/profiles/${step.settings.service_profile_id}/entities/${step.settings.entity}/contexts`).then((response) => {
        if (step.settings.method === 'getAttachedContent') {
          setContexts({
            "Id": {
              "validation": "",
              "isRequired": true,
              "key": "Id",
              "label": "Record ID",
              "dataType": "string",
              "metadata": null
            }
          })
        } else {
          setContexts(response.data[step.settings.method].inputs);
        }

        setLoading(false)
      })
    }
  }, [step.settings.service_profile_id])

  if (loading) {
    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <Loader />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {
              step.settings.map ?
                <MappingListTable step={step} contexts={contexts} /> :
                <p className="min-w-full divide-y divide-gray-200 p-6">No mapping</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepConfigurationMappingTab;
