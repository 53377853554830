import Header from "../components/Header";
import axios from "axios";
import {useEffect, useState} from "react";
import {DateTime} from 'luxon'
import {useNavigate} from "react-router-dom";
import {SkeletonTable} from "../components/Loaders";
import CreateNewState from "../components/CreateNewState";
import {PlusSmIcon as PlusSmIconSolid, SwitchHorizontalIcon, EyeIcon, PencilIcon} from "@heroicons/react/solid";
import {IsAdmin} from "../contexts/auth";

/**
 * Render integration items in table
 * @param integrations
 * @returns {*}
 * @constructor
 */
const IntegrationList = ({integrations}) => {
  const navigate = useNavigate();

  return integrations.map((integration) => {
    return (
      <tr key={integration.id}>
        <td className="px-6 py-4 text-sm font-medium text-gray-900">{integration.name}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{integration.description}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{integration.active ? 'Yes' : 'No'}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{DateTime.fromISO(integration.created_at).toLocaleString(DateTime.DATETIME_MED)}</td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex items-center">
          <EyeIcon className="h-6 w-6 cursor-pointer" aria-hidden="true" onClick={() => navigate(`/integrations/${integration.id}`)} />
          <IsAdmin>
            <PencilIcon className="h-6 w-6 cursor-pointer" aria-hidden="true" onClick={() => navigate(`/integrations/${integration.id}/edit`)} />
          </IsAdmin>
        </td>
      </tr>
    )
  })
}

/**
 *
 * @param integrations
 * @returns {JSX.Element}
 * @constructor
 */
const IntegrationsTable = ({integrations}) => {
  if (integrations.length === 0) {
    return <CreateNewState
      text="No Integrations. Create a new one."
      icon={<SwitchHorizontalIcon className={"w-10 h-10 mx-auto"}/>}
      path="/integrations/create" />
  }

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Active
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Updated At
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                <IntegrationList integrations={integrations} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Integrations = () => {
  const navigate = useNavigate();
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/workflows').then((response) => {
      setIntegrations(response.data)
      setLoading(false)
    })
  }, [])

  return (
    <main>
      <Header title="Integrations">
        {/*<IsAdmin>*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
        {/*    onClick={() => navigate('/integrations/create')}*/}
        {/*  >*/}
        {/*    <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />*/}
        {/*  </button>*/}
        {/*</IsAdmin>*/}
      </Header>
      {
        loading ?
          <SkeletonTable rowCount={10} rowHeightClass="h-8"/> :
          <IntegrationsTable integrations={integrations}/>
      }
    </main>
  )
}

export default Integrations;
