import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {useEffect, useState} from "react";
import axios from "axios";
import {SkeletonTable} from "../Loaders";
import {DateTime} from "luxon";
import classNames from "../../utils/classNames";

/**
 * Handles pagination request
 * @param setPagination
 * @param nextPageResults
 * @returns {Promise<void>}
 */
const paginate = async (setPagination, nextPageResults) => {
  const {data} = await axios.get(nextPageResults.replace(process.env.API_URL, ""))
  setPagination(data)
}

/**
 * Pagination count indicator
 * @param pagination
 * @returns {JSX.Element}
 * @constructor
 */
const CountIndicator = ({pagination}) => {
  const from = pagination.from || 0;
  const to = pagination.to || 0;
  return (
    <div>
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{from}</span> to <span className="font-medium">{to}</span> of{' '}
        <span className="font-medium">{pagination.total}</span> results
      </p>
    </div>
  )
}

/**
 * Pagination element for data tables
 * @param pagination
 * @param setPagination
 * @returns {JSX.Element}
 * @constructor
 */
const Pagination = ({pagination, setPagination, classes}) => {

  const renderLongPaginationOptions = () => {
    if (pagination.last_page <= 3) {
      return null;
    }

    const options = [
      <span key={1} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">...</span>,
    <div
      key={2}
      className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
    >
      {pagination.last_page - 2}
    </div>,
    <div
      key={3}
      className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
    >
      {pagination.last_page - 1}
    </div>,
    <div
      key={4}
      className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
    >
      {pagination.last_page}
    </div>];

    return options.map((item => item))
  }

  return (
    <div className={classNames("bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:rounded-lg mb-2", classes)}>
      <div className="flex-1 flex justify-between sm:hidden">
        <div
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        >
          Previous
        </div>
        <div
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        >
          Next
        </div>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <CountIndicator pagination={pagination} />
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <div
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" onClick={async () => {
                if (pagination.prev_page_url) {
                  await paginate(setPagination, pagination.prev_page_url)
                }
              }}/>
            </div>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {
              (pagination.current_page === 3 && pagination.last_page === 3) ?
                (
                  <div
                    onClick={async () => await paginate(setPagination, pagination.first_page_url)}
                    className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                  >
                    {1}
                  </div>
                ) : null
            }
            {
              (pagination.prev_page_url) ? (
                <div
                  onClick={async () => await paginate(setPagination, pagination.prev_page_url)}
                  className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  {pagination.current_page - 1}
                </div>
              ) : null
            }
            <div
              aria-current="page"
              className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {pagination.current_page}
            </div>
            {
              (pagination.next_page_url) ?
                (
                  <div
                    onClick={async () => await paginate(setPagination, pagination.next_page_url)}
                    className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                  >
                    {pagination.current_page + 1}
                  </div>
                ) : null
            }
            {
              (pagination.current_page === 1 && pagination.last_page === 3) ?
                (
                  <div
                    onClick={async () => await paginate(setPagination, pagination.last_page_url)}
                    className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                  >
                    {3}
                  </div>
                ) : null
            }
            {
              renderLongPaginationOptions()
            }
            <div
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" onClick={async () => {
                if (pagination.next_page_url) {
                  await paginate(setPagination, pagination.next_page_url)
                }
              }}/>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a paginated table of integration transactions
 * @param integrationId
 * @returns {JSX.Element}
 * @constructor
 */
const Transactions = ({integrationId}) => {
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    data: [],
    from: '',
    to: ''
  });

  useEffect(() => {
    async function getStats() {
      const {data} = await axios.get(`/dashboard/stats/transactions/${integrationId}`)
      setPagination(data)
      setLoading(false)
    }

    getStats();
  }, [integrationId])

  if (loading) {
    return <SkeletonTable rowCount={10} rowHeightClass="h-8"/>
  }

  return (
    <div className="flex flex-col">
      <Pagination pagination={pagination} setPagination={setPagination} />
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Entity
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Action
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Resource ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {pagination.data.map((transaction) => (
                <tr key={transaction.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{DateTime.fromSQL(transaction.transaction_date, {zone: 'UTC'}).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.entity}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.action}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.resource_id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {
                      transaction.status === 'success' ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800">success</span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-rose-100 text-rose-800">fail</span>
                      )
                    }
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination pagination={pagination} setPagination={setPagination} classes="mt-1"/>
    </div>
  );
}

export default Transactions;
