import {CheckCircleIcon, LockClosedIcon, XIcon} from "@heroicons/react/solid";
import {useNotification} from "../contexts/notification";
import classNames from "../utils/classNames";

const styles = {
  success: {
    backgroundColor: "bg-emerald-50",
    iconColor: "text-emerald-400",
    textColor: "text-emerald-800",
    dismissButton: "bg-emerald-50 text-emerald-500 hover:bg-emerald-100 focus:ring-offset-emerald-50 focus:ring-emerald-600",
    icon: CheckCircleIcon
  },

  error: {
    backgroundColor: "bg-rose-50",
    iconColor: "text-rose-400",
    textColor: "text-rose-800",
    dismissButton: "bg-rose-50 text-rose-500 hover:bg-rose-100 focus:ring-offset-rose-50 focus:ring-rose-600",
    icon: LockClosedIcon
  },
}

const Notification = () => {
  const { message, type, removeMessage } = useNotification();

  if (! message) {
    return null;
  }

  const Icon = styles[type].icon

  return (
    <div className={classNames(
      !message
        ? 'hidden'
        : styles[type].backgroundColor,
      'rounded-md p-4 absolute right-5 top-1 z-10'
    )}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={classNames(styles[type].iconColor, "h-5 w-5")} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={classNames(styles[type].textColor, "text-sm font-medium")}>{message}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={classNames(styles[type].dismissButton, "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2")}
              onClick={() => removeMessage()}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
