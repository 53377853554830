import {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import axios from "axios";
import {DateTime} from "luxon/build/es6/luxon";

/**
 * Displays a doughnut chart showing success and failures for
 * a particular organization
 * @param integrationId
 * @returns {JSX.Element}
 * @constructor
 */
const TransactionsSuccessAndErrorsByMonth = ({integrationId}) => {
  const [success, setSuccess] = useState([]);
  const [fails, setFails] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    async function getStats() {
      const {data: {fails, success}} = await axios.get(`/dashboard/stats/transactionsSuccessAndErrorsByMonth/${integrationId}`)
      setSuccess(success.map(item => item.transaction_count))
      setFails(fails.map(item => item.transaction_count))
      setLabels(success.map((item) => {
        return DateTime.fromSQL(item.date).weekdayShort
      }));
    }

    getStats();

    return () => {
      setFails([]); // This worked for me
      setSuccess([]); // This worked for me
      setLabels([]); // This worked for me
    };
  }, [integrationId])

  return (
    <div className="max-w-7xl bg-white rounded">
      <div className="py-3 px-5 w-full bg-gray-200 rounded-t-md">Weekly Transactions</div>
      <div className="p-3">
        <Line data={{
          labels,
          datasets: [{
            label: 'Success',
            data: success,
            fill: true,
            borderColor: '#818cf8',
            backgroundColor: '#818cf8',
            tension: 0.1,
          }, {
            label: 'Error',
            data: fails,
            fill: true,
            borderColor: '#fb7185',
            backgroundColor: '#fb7185',
            tension: 0.1
          }]
        }} type="line" options={{
          plugins: {
            legend: {
              display: true
            },
            title: {
              text: 'Daily Transactions',
              display: false
            }
          }
        }}/>
      </div>
    </div>
  );
}

export default TransactionsSuccessAndErrorsByMonth;
