import {LockClosedIcon} from "@heroicons/react/outline";
import {useState} from "react";
import axios from "axios";
import {useAuth} from "../contexts/auth";
import {Navigate} from "react-router-dom";
import logoIcon from './../images/logo.png'

const ErrorContainer = ({show}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="rounded-md bg-rose-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <LockClosedIcon className="h-5 w-5 text-rose-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-rose-800">Your username or password is incorrect</p>
        </div>
      </div>
    </div>
  );
}
const Login = () => {
  const [user, setUser] = useState('');
  const [hasError, setHasError] = useState(false);
  const [password, setPassword] = useState('');
  const {token, addToken} = useAuth();

  const login = async () => {
    try {
      const request = await axios.post('/login', {
        email: user,
        password
      })

      addToken(request.data.access_token)
    } catch (e) {
      setHasError(true)
      console.log(e)
    }
  }

  if (token) {
    return <Navigate to="/" />
  }

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-16 w-auto"
            src={logoIcon}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        </div>
        <ErrorContainer show={hasError}/>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => setUser(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="button"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={async () => {
                setHasError(false)
                await login()
              }}
            >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
