import {Disclosure} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/outline";

/**
 * Renders the source value for an individual mapped field
 * @param field
 * @param integrationSteps
 * @returns {JSX.Element|null}
 * @constructor
 */
const SourceValue = ({field, integrationSteps}) => {
  if (field.default) {
    return (<p className="text-sm text-gray-500">Value: default: {field.default}</p>);
  }

  if (!field.source) {
    return null;
  }

  const sourceIndex = Number(field.source[0].substr(0, 1));
  const sourceField = field.source[0].substr(2)
  const sourceEntity = integrationSteps[sourceIndex].settings.entity

  return (
    <p className="text-sm text-gray-500">Value: {`${sourceEntity}.${sourceField}`}</p>
  );
}

/**
 * Renders integration mappings
 * @param integration
 * @returns {JSX.Element}
 * @constructor
 */
const IntegrationMapping = ({integration}) => {
  const resources = integration.steps.reduce((prev, current) => {
    if (['create', 'update'].includes(current.settings.method)) {
      prev[current.settings.entity] = {...prev[current.settings.entity], ...current.settings.map}
    }

    return prev;
  }, {});

  return (
    <div className="w-full px-4 pt-16">
      <div className="w-full p-2 bg-white rounded-2xl">
        {
          Object.keys(resources).map((resourceName) => {
            return (
              <Disclosure key={resourceName}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-md font-medium text-left text-indigo-900 bg-indigo-100 rounded-lg hover:bg-indigo-200 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75 mb-2">
                      <span>{resourceName}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-indigo-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      <div className="grid grid-cols-3 gap-2">
                        {Object.keys(resources[resourceName]).filter(fieldName => fieldName !== 'Id').map((fieldName) => (
                          <div key={fieldName} className="bg-gray-100 p-2">
                            <p className="text-sm font-medium text-gray-900">{fieldName}</p>
                            <SourceValue field={resources[resourceName][fieldName]} integrationSteps={integration.steps}/>
                          </div>
                        ))}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )
          })
        }
      </div>
    </div>
  )
}

export default IntegrationMapping;
